import { tv, VariantProps } from "@heroui/react";
import { APP_ACP, APP_CAREPROVIDER, APP_CLINIC } from "core/consts";
import {
  ENV_DEMO,
  ENV_DEVELOPMENT,
  ENV_PREPROD,
  ENV_PRODUCTION,
  ENV_STAGING,
} from "core/model/config";

export type LoginPageVariants = VariantProps<typeof loginPage>;

export const loginPage = tv({
  slots: {
    page: "bg-beige-100 flex flex-col min-h-screen max-w-screen w-full items-center justify-center bg-cover bg-fixed bg-center py-6 space-y-2 pb-24 tablet:pb-6",
    devToolContainer:
      "flex flex-col max-w-screen w-full items-center space-y-2",
    envSwitcher:
      "flex w-fit space-x-2 rounded-md border-1 border-primary bg-white p-2",
    loginSuggestion: "flex w-fit space-x-2 ",
    appSuggestion: "flex w-fit space-x-2 ",
    container:
      "gap-y-10 bg-white bg-opacity-40 box-border flex min-h-[592px] w-full max-w-[476px] flex-col justify-between rounded-2xl p-8 shadow-lg ring-1 ring-white backdrop-blur-2xl",
    content: "flex flex-col gap-10",
    header: "h-10",
    form: "flex flex-col gap-8",
    formContent: "flex flex-col gap-3",
    title: "font-lexend text-lg font-bold uppercase",
    footer:
      "flex flex-col gap-y-6 font-lexend text-sm font-light [&_a]:font-medium",
    footerLinks: "flex flex-col gap-4",
    footerDivider: "border-t border-gray-400",
  },
  variants: {
    env: {
      [ENV_DEVELOPMENT]: {},
      [ENV_STAGING]: {},
      [ENV_PREPROD]: {},
      [ENV_DEMO]: {
        page: "!bg-login-demo",
      },
      [ENV_PRODUCTION]: {},
    },
    app: {
      [APP_CLINIC]: {
        page: "bg-login-sender",
      },
      [APP_CAREPROVIDER]: {
        page: "bg-login-receiver",
      },
      [APP_ACP]: {
        page: "bg-login-sender",
      },
    },
  },
});
