import {
  ACCOUNT_ROLE_ADMIN_TRANSLATIONS,
  ACCOUNT_ROLE_CARESEEKER_SSO_USER_MANAGEMENT,
  ACCOUNT_ROLE_CARESEEKER_USER_MANAGEMENT,
  ACCOUNT_ROLE_COST_APPROVER,
  ACCOUNT_ROLE_REPORT,
  ACCOUNT_ROLE_SENDER_IT,
  ACCOUNT_STATUS_ACTIVE,
  ACCOUNT_STATUS_CREATED,
  ACCOUNT_STATUS_INACTIVE,
  ACCOUNT_TYPE_PROVIDER_SEARCH,
  ACCOUNT_TYPE_STAFF,
  ACCOUNT_TYPE_TOOL,
  CREATE_PATIENT_ROLES,
} from "core/consts";
import { Account, AccountRole, GetOntologyType, ToType } from "core/types";
import { getUnixTime } from "date-fns";
import { ERROR_COLOR, SUCCESS_COLOR } from "ds_legacy/materials/colors";
import {
  getDefaultProviderAccountId,
  selectCareseekers,
  selectCredentials,
  selectIdentification,
} from "reduxentities/selectors";
import { AuthState } from "reduxentities/slices/authSlice";
import { RootState } from "reduxentities/store";
import Translations from "translations/types";

const isNumber = (v: ToType) => typeof v === "number";

export function getSalutation({
  gender,
  getOntology,
  salutation,
  title,
}: {
  gender?: number;
  getOntology: GetOntologyType;
  salutation?: number;
  title?: number;
}): string | null {
  if (title != null && isNumber(title))
    return getOntology({ type: "salutation", key: title });
  if (gender != null && isNumber(gender))
    return getOntology({ type: "salutation", key: gender });
  if (salutation != null && isNumber(salutation))
    return getOntology({ type: "salutation", key: salutation });

  return null;
}

export function hasAccessToReport(
  account: Account | null | undefined,
  activeRoles: Array<number> | undefined,
) {
  if (!account) return false;
  if (account.account_type == ACCOUNT_TYPE_STAFF) return true;
  if (!activeRoles) return false;

  return activeRoles.includes(ACCOUNT_ROLE_REPORT);
}

export function hasAccessToPatientDashboard(
  account: Account | null | undefined,
  activeRoles: Array<number> | undefined,
) {
  if (!account) return false;
  if (account.account_type == ACCOUNT_TYPE_STAFF) return true;
  if (!activeRoles) return false;

  const dashboardAllowedRoles = [
    ...CREATE_PATIENT_ROLES,
    ACCOUNT_ROLE_COST_APPROVER,
  ];
  return activeRoles.some((activeRole) =>
    dashboardAllowedRoles.includes(
      activeRole as (typeof dashboardAllowedRoles)[number],
    ),
  );
}

export function hasCareseekerRole(
  account: Account | undefined,
  careseekerId: number | undefined,
  roleId: AccountRole,
) {
  if (!account) return false;

  const careseekerRoles = account.roles?.careseeker_roles?.find(
    (entry) => entry.careseeker?.id === careseekerId,
  );
  if (!careseekerRoles) return false;

  return careseekerRoles.roles?.includes(roleId);
}

export function isUserManager(account: Account, careseekerId: number) {
  const hasUserManagementRole = hasCareseekerRole(
    account,
    careseekerId,
    ACCOUNT_ROLE_CARESEEKER_USER_MANAGEMENT,
  );

  return hasUserManagementRole;
}
export function isSsoUserManager(
  account: Account | undefined,
  careseekerId: number | undefined,
) {
  return hasCareseekerRole(
    account,
    careseekerId,
    ACCOUNT_ROLE_CARESEEKER_SSO_USER_MANAGEMENT,
  );
}

export function isActiveAccount(account: Account) {
  return account.status === ACCOUNT_STATUS_ACTIVE;
}
export function isStaffAccount(account: Account | undefined) {
  return account?.account_type === ACCOUNT_TYPE_STAFF;
}

export function hasAccessToUserManagement(
  account: Account | null | undefined,
  activeRoles: Array<number> | undefined,
) {
  if (!account || !activeRoles) return false;

  return (
    activeRoles.includes(ACCOUNT_ROLE_CARESEEKER_USER_MANAGEMENT) ||
    activeRoles.includes(ACCOUNT_ROLE_CARESEEKER_SSO_USER_MANAGEMENT)
  );
}

export function hasAccessToOnPremDebug(
  account: Account | null | undefined,
  activeRoles: Array<number> | undefined,
) {
  if (!account || !activeRoles) return false;

  return activeRoles.includes(ACCOUNT_ROLE_SENDER_IT);
}

export type GetNameOptions = {
  providerSearchAccountName?: string;
  withAcademicTitle?: boolean;
  withFirstInitial?: boolean;
  withSalutation?: boolean;
};

const defaultOptions = {
  withAcademicTitle: true,
  withSalutation: true,
};

export function getAccountStatusColor(
  status: number | null | undefined,
): string | undefined {
  switch (status) {
    case ACCOUNT_STATUS_CREATED:
      return undefined;
    case ACCOUNT_STATUS_INACTIVE:
      return ERROR_COLOR;
    case ACCOUNT_STATUS_ACTIVE:
      return SUCCESS_COLOR;
    default:
      return undefined;
  }
}

// todo: add this in BE in the event context
export const getProviderSearchAccountName = ({
  patientId,
  translations,
}: {
  patientId: string | undefined;
  translations: Translations;
}) => `${translations.auctionRequest.bcpProfileName} ${patientId ?? ""}`;

export const getName = (
  account: Account | null | undefined,
  getOntology: GetOntologyType,
  options: GetNameOptions = defaultOptions,
) => {
  if (!account) return "";
  if (account.account_type === ACCOUNT_TYPE_TOOL) return "";

  if (account.account_type === ACCOUNT_TYPE_PROVIDER_SEARCH) {
    if (!options?.providerSearchAccountName) {
      console.error("no provider search account name provided");
      return "";
    }
    return options.providerSearchAccountName;
  }

  if (account.id === getDefaultProviderAccountId()) return "";

  if (!account.first_name && !account.last_name) return "";

  const adornments = [];
  const name = `${
    account.first_name
      ? options.withFirstInitial
        ? `${account.first_name[0].toUpperCase()}.`
        : account.first_name
      : ""
  } ${account.last_name || ""}`.trim();
  const normalizedOptions = { ...defaultOptions, ...options };

  const salutation =
    normalizedOptions.withSalutation &&
    getSalutation({ ...account, getOntology });
  if (salutation) adornments.push(salutation);
  if (
    normalizedOptions.withAcademicTitle &&
    account.academic_title != null &&
    account.academic_title > 0
  )
    adornments.push(
      getOntology({ type: "academicTitle", key: account.academic_title }),
    );
  const concatAdornments = options.withFirstInitial
    ? [...new Set(adornments.join(" ").split(" "))].join(" ")
    : adornments.join(" ");

  return `${concatAdornments} ${name}`.trim();
};

export function convertSocialWorker(
  socialWorker: Account | null,
  getOntology: GetOntologyType,
  optionsArg?: GetNameOptions,
) {
  const options =
    optionsArg != null ? { ...defaultOptions, ...optionsArg } : defaultOptions;
  return {
    value: socialWorker?.id,
    name: getName(socialWorker, getOntology, options),
    originalName: getName(socialWorker, getOntology, {
      withSalutation: false,
    }),
  };
}

export const convertSocialWorkers = (
  socialWorkers: Array<Account> | undefined,
  getOntology: GetOntologyType,
  optionsArg?: GetNameOptions,
): Array<{
  name: string;
  originalName: string;
  value: number | null | undefined;
}> => {
  if (!socialWorkers) return [];

  return (
    socialWorkers
      .filter(
        (s) =>
          s.first_name && s.last_name && s.account_type !== ACCOUNT_TYPE_STAFF,
      )
      .map((s) => convertSocialWorker(s, getOntology, optionsArg))
      // Filter out empty names.
      // #TODO: Automated task accounts may require distinct handling strategies.
      .filter((s) => s.name && s.value)
      .sort((a, b) => a.originalName.localeCompare(b.originalName))
  );
};

export const getInitialsForAccount = (account?: {
  first_name?: string;
  last_name?: string;
}) => {
  if (!account) return "";
  if (!account.first_name || !account.last_name) return "";

  return [account.first_name, account.last_name]
    .map((n) => n.charAt(0))
    .join("");
};

export const TOKEN_STATUS = {
  NO_TOKEN: "noToken",
  EXPIRED: "expired",
  VALID: "valid",
} as const;

export function checkToken(state: RootState) {
  const credentials = selectCredentials(state);
  return checkTokenStatus(credentials);
}

export function checkTokenStatus(credentials: AuthState["credentials"]) {
  if (!credentials?.token) return TOKEN_STATUS.NO_TOKEN;

  if (
    credentials.expiration &&
    credentials.expiration < getUnixTime(new Date())
  ) {
    return TOKEN_STATUS.EXPIRED;
  }

  return TOKEN_STATUS.VALID;
}

export const isAdminLogged = (state: RootState) => {
  const check = checkToken(state);
  if (check !== TOKEN_STATUS.VALID) return check;

  const identification = selectIdentification(state);

  if (!identification?.admin_roles || identification.admin_roles.length <= 0)
    return "noRoles";

  return "ok";
};

export const isCareseekerLogged = (state: RootState): string => {
  const check = checkToken(state);
  if (check !== TOKEN_STATUS.VALID) return check;
  if (
    state.auth?.identification?.account?.account_type ===
    ACCOUNT_TYPE_PROVIDER_SEARCH
  )
    return "incorrectAccountType";

  if (!selectCareseekers(state)) return "noRoles";

  return "ok";
};

export const isProviderSearchLogged = (state: RootState): string => {
  const check = checkToken(state);
  if (check !== TOKEN_STATUS.VALID) return check;

  if (state.auth?.identification?.account?.id == null) return "noAccount";

  if (
    state.auth?.identification?.account?.account_type !==
    ACCOUNT_TYPE_PROVIDER_SEARCH
  )
    return "incorrectAccountType";

  return "ok";
};

export const isTranslationsAdmin = (account: Account | null | undefined) =>
  account?.roles?.admin_roles?.includes(ACCOUNT_ROLE_ADMIN_TRANSLATIONS);

export function isRecareAccount(account: Account | null | undefined) {
  if (account?.account_type === ACCOUNT_TYPE_STAFF) return true;

  return false;
}

export function getAccountValue(
  account: Account | undefined,
  getOntology: GetOntologyType,
  nameOptions: GetNameOptions,
): number {
  if (!account) return -1;

  const converted = convertSocialWorker(account, getOntology, nameOptions);

  return converted?.value ?? -1;
}

export function isAccountLinkedToSeveralProviders(
  account: Account | undefined,
) {
  return (
    account?.roles?.careprovider_roles?.length &&
    account?.roles?.careprovider_roles?.length > 1
  );
}
