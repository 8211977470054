import { ApolloError, ServerError } from "@apollo/client";
import { BackendErrorCode } from "core/types";
import Translations from "translations/types";

export type ApolloNetworkErrorResponse = {
  networkError?: ServerError;
};

const isError = (err: unknown): err is Error => {
  return err instanceof Error;
};

export const getError = (err: unknown): Error => {
  if (isError(err)) {
    return err;
  } else {
    return new Error("Generic error");
  }
};

const isApolloError = (err: unknown): err is ApolloError => {
  return err instanceof ApolloError;
};

export const getApolloError = (err: unknown): ApolloError => {
  if (isApolloError(err)) {
    return err;
  } else {
    return new ApolloError({ errorMessage: "Generic error" });
  }
};

export const getApolloNetworkErrorMessage = (err: unknown) => {
  const error = getApolloError(err) as ApolloNetworkErrorResponse;

  const errorMessage =
    typeof error?.networkError?.result === "object"
      ? error.networkError.result.error
      : error?.networkError?.result;

  return errorMessage || JSON.stringify(err);
};

export const getErrorMessage = (err: unknown): string => {
  return getError(err).message;
};

/**
 * This type guard checks if a given error code exists in our translation system.
 * For the full list of error codes and their meanings, see:
 * https://www.notion.so/recare/Custom-Error-Codes-Mapping-To-Translations-198a736bb0a880c09a8afa5b78aba2c8
 */
export const isBackendErrorCode = (
  value: unknown,
  translations: Translations,
): value is BackendErrorCode => {
  const key = String(value);
  return Object.keys(translations.general.errorCodes).includes(key);
};
