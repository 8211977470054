import { useTheme } from "@mui/material/styles";
import { handleKeyDown } from "core/model/accessibility/keyboardActions";
import { AssessmentSlug } from "core/types";
import { DISABLED_COLOR, TEXT_STEPPER } from "ds_legacy/materials/colors";
import { margin } from "ds_legacy/materials/metrics";
import { Body } from "ds_legacy/materials/typography";
import React from "react";
import styled from "styled-components";
import { DotWithBorder } from "../Dot";

export const StepContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${margin(0, 1)};
  cursor: ${(props) => (props.onClick ? "pointer" : "default")};
  width: 100%;
`;

export type StepProps = {
  active?: boolean;
  children: React.ReactNode;
  color?: string | null | undefined;
  disabled?: boolean;
  done?: boolean;
  dot?: boolean;
  name?: string;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  slug?: AssessmentSlug;
};
export default function Step({
  active,
  children,
  color,
  disabled,
  done,
  dot,
  name,
  onClick,
  slug,
}: StepProps) {
  const theme = useTheme();

  return (
    <StepContainer
      tabIndex={0}
      onKeyDown={handleKeyDown({
        onConfirm: !disabled ? () => onClick?.() : undefined,
      })}
      onClick={!disabled ? onClick : undefined}
      data-slug={slug}
    >
      <Body
        className={active ? "active" : undefined}
        margin="unset"
        color={
          disabled
            ? DISABLED_COLOR
            : active === true || done === true
            ? theme.palette.primary.main
            : TEXT_STEPPER
        }
        fontWeight={active === true ? "bold" : "normal"}
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        overflow="hidden"
        data-testid={name}
      >
        {children}
      </Body>
      {dot && <DotWithBorder color={color || undefined} />}
    </StepContainer>
  );
}
