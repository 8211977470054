import { DialogActions } from "@mui/material";
import { LoginFnError } from "apollo/mutations/login";
import { useEnvContext } from "context/EnvContext";
import {
  APP_CAREPROVIDER,
  APP_CLINIC,
  QUERY_PROGRESS_FAILED,
  QUERY_PROGRESS_PENDING,
  RESPONSIVE_BREAKPOINT_MOBILE,
} from "core/consts";
import { getConfig } from "core/model/config";
import { LOCAL_STORAGE_KEYS } from "core/model/localStorage";
import { isDemo, isPreprod } from "core/model/utils/featureFlags";
import { getTopLevelDomain, getUrlEnvSuffix } from "core/model/utils/urls";
import { Env, QueryProgress } from "core/types";
import { millisecondsToMinutes } from "date-fns";
import { Banner } from "ds/ui";
import { FlagFab } from "ds_legacy/components/FlagFab/Fab";
import { DeFlagIcon } from "ds_legacy/components/FlagIcon";
import LinkV2 from "ds_legacy/components/LinkV2";
import RSButton from "ds_legacy/components/RSButton";
import { RecareLogoOnly } from "ds_legacy/components/RecareLogo";
import { SpinnerPage } from "ds_legacy/components/Spinner";
import { TextInputField } from "ds_legacy/components/TextInputField";
import {
  BORDER_COLOR,
  ICON_DARK,
  ICON_GREY,
  WHITE,
} from "ds_legacy/materials/colors";
import { HorizontalLayout, VerticalLayout } from "ds_legacy/materials/layouts";
import {
  border,
  dp,
  margin,
  padding,
  sizing,
} from "ds_legacy/materials/metrics";
import { SHADOW_DROP_SMALL } from "ds_legacy/materials/shadows";
import {
  Body,
  FONT_SIZE_12,
  FONT_SIZE_16,
  LINE_HEIGHT_16,
  Link,
  Subheading,
} from "ds_legacy/materials/typography";
import LoginSuggestions from "dsl/ecosystems/CareproviderOnboardingLoginPage/LoginSuggestions";
import { ChevronLeftIcon, LockIcon, MailIcon } from "lucide-react";
import { usePanda } from "pandatracker-client";
import React, {
  CSSProperties,
  Dispatch,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useTranslations } from "translations";
import { TranslationComposition } from "translations/helpers";
import Translations from "translations/types";

export type EnhancedLoginError = LoginFnError & {
  remaining_time: number;
  status: number;
};

export const Overlay = styled.div`
  display: flex;
  background: radial-gradient(#40404b, #111118) rgba(34, 34, 40, 0.94);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
`;

export const LockCenter = styled.div`
  display: flex;
  align-items: center;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  height: ${sizing(9)};
  padding: ${padding(0, 3)};
  border-top-right-radius: ${dp(5)};
  border-top-left-radius: ${dp(5)};
  background: ${(props) => props.theme.palette.primary.main};
`;

export const HeaderWithIcon = styled.div`
  display: flex;
  align-items: center;
  height: ${sizing(9)};
  padding: ${padding(0, 3, 0, 1)};
  border-top-right-radius: ${dp(5)};
  border-top-left-radius: ${dp(5)};
  background: ${(props) => props.theme.palette.primary.main};
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  width: ${sizing(50)};
  height: ${sizing(4)};
  border-top: ${border({ color: BORDER_COLOR })};
  justify-content: center;
  font-size: ${FONT_SIZE_12};
`;

export const PasswordResetPageLink = styled(Subheading)`
  font-size: ${FONT_SIZE_12};
  margin: ${margin(-0.5, 2)};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${sizing(50)};
`;

export const FooterWrapper = styled.div`
  display: flex;
  padding: ${padding(2, 0, 0, 0)};
  flex-grow: 1;
  align-items: flex-end;
  justify-content: center;
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: ${margin(2, 3)};
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Widget = styled.div<{ height?: string; minHeight?: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${sizing(50)};
  margin: 0 auto;
  border-radius: ${dp(5)};
  height: ${(props) => props.height || dp(500)};
  max-height: 100vh;
  background: white;
  box-shadow: 0 0 40px 4px #111118;
  @media screen and (max-width: ${dp(RESPONSIVE_BREAKPOINT_MOBILE)}) {
    & {
      height: 100vh;
      width: 100vw;
    }
  }
  ${({ minHeight }) => minHeight && `min-height: ${minHeight};`}
`;

export const OnboardingWidget = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${sizing(50)};
  margin: 0 auto;
  border-radius: ${dp(5)};
  height: ${dp(500)};
  max-height: 100vh;
  background: white;
  box-shadow: ${SHADOW_DROP_SMALL};
  @media screen and (max-width: ${dp(RESPONSIVE_BREAKPOINT_MOBILE)}) {
    & {
      height: 100vh;
      width: 100vw;
    }
  }
`;

function getTranslationCompositionLink(translation: string, link: string) {
  return (
    <TranslationComposition translations={translation} withOptions>
      {([before, selectLink, after]) => (
        <span>
          {before}
          <LinkV2 href={link}>{selectLink}</LinkV2>
          {after}
        </span>
      )}
    </TranslationComposition>
  );
}

export function getLoginError(err: LoginFnError): EnhancedLoginError {
  const error: EnhancedLoginError = {
    status: -1,
    remaining_time: 0,
    ...err,
  };

  try {
    if (err.message != null && typeof err.message === "string") {
      const json = JSON.parse(err.message);

      error.status = json.status;
      if (json.body.remaining_time) {
        error.remaining_time = json.body.remaining_time;
      }
    }
  } catch (e) {
    console.warn("Could not parse error message", e);
  }

  return error;
}

export function getLoginFailureReason({
  challenge,
  rawError,
  remaining_time,
  status,
  token,
  translations,
}: {
  challenge: string | undefined;
  rawError: string;
  remaining_time: number;
  status: number;
  token: string | undefined;
  translations: Translations;
}) {
  let reason = "";

  switch (status) {
    case 429:
      reason =
        translations.login.biometricfailedAlert.falsePasswordLimitReached({
          timer: String(millisecondsToMinutes(remaining_time) + 1),
        });
      break;
    default:
      if (rawError.includes("connected with unauthorized IP")) {
        reason = "Connect to 'ACP Connect' in Perimeter81";
      } else if (rawError.includes("no private key")) {
        reason = translations.auctionResponse.accessDeniedError;
      } else if (rawError === translations.login.toastInactiveProvider) {
        reason = translations.login.toastInactiveProvider;
      } else if (rawError === "cleandata in progress") {
        reason = "cleandata in progress";
      } else {
        if (challenge) {
          const datetimestring =
            window.sessionStorage.getItem(
              LOCAL_STORAGE_KEYS.CHALLENGE_DATETIME,
            ) ?? "";
          reason =
            translations.login.challengeScreen.verificationInvalidTimestamp({
              datetimestring,
            });
        } else if (token) {
          reason = "Invalid token";
        } else if (rawError.toLowerCase().includes("seald")) {
          reason = rawError;
        } else {
          reason = translations.login.wrongEmailPassword;
        }
      }
      break;
  }

  return reason;
}

export function useAutofilled(refs: Array<React.MutableRefObject<ToType>>) {
  const [isAutofilled, setIsAutofilled] = useState(false);
  /* eslint-disable consistent-return */
  useLayoutEffect(() => {
    if (!isAutofilled && refs.length > 0 && refs[0].current != null) {
      const timeout = setTimeout(() => {
        let accumulator = true;
        refs.forEach((r) => {
          try {
            if (r.current)
              accumulator =
                accumulator && r.current.matches(":-webkit-autofill");
          } catch (error) {
            // no ':-webkit-autofill' selector
            accumulator = false;
          }
        });
        setIsAutofilled(accumulator);
      }, 200);
      return () => clearTimeout(timeout);
    }
  }, []);
  return isAutofilled;
}

export const getTitleAndUrl = (
  app: number,
  env: string,
  translations: Translations,
) => {
  const { domain } = getConfig(env as Env);

  switch (app) {
    case APP_CLINIC:
      return {
        title: isDemo
          ? translations.login.clinic.loginTitleDemo.toUpperCase()
          : isPreprod
          ? translations.login.clinic.loginTitlePreprod.toUpperCase()
          : translations.login.clinic.loginTitle.toUpperCase(),
        redirectTitle: translations.login.clinic.changeToReceiver,
        redirectUrl: `https://partner${getUrlEnvSuffix(
          env,
        )}.${domain}/careprovider/auth`,
        showEnvironmentWarning: isDemo || isPreprod,
      };

    case APP_CAREPROVIDER:
      return {
        title: translations.login.care.loginTitle.toUpperCase(),
        redirectTitle: translations.login.care.changeToSender,
        redirectUrl: `https://app${getUrlEnvSuffix(env)}.${domain}/app/auth`,
        showEnvironmentWarning: false,
      };

    default:
      return {
        title: translations.login.loginTitle.toUpperCase(),
        redirectTitle: "",
        redirectUrl: "",
        showEnvironmentWarning: false,
      };
  }
};

const SWITCHER_FLAGS: {
  [key: string]: React.ComponentType<{ active?: boolean }>;
} = { de: DeFlagIcon };

const getCountrySwitcherUrl = ({
  currentApp,
  urlSuffix,
}: {
  currentApp: string;
  urlSuffix: string;
}) => `https://${currentApp}${urlSuffix}.recaresolutions.com}`;

const CountrySwitcher = ({ app, env }: { app: number; env: string }) => {
  const currentApp = app === APP_CAREPROVIDER ? "partner" : "app";
  const urlSuffix = getUrlEnvSuffix(env);
  const initialFlagState = getTopLevelDomain().toLowerCase();
  const containerStyle: CSSProperties = {
    position: "fixed",
    bottom: sizing(2),
    right: sizing(3),
    zIndex: 100,
  };
  return (
    <FlagFab
      flagState={initialFlagState}
      flags={SWITCHER_FLAGS}
      containerStyleOverride={containerStyle}
      onFlagChange={() => {
        const URL = getCountrySwitcherUrl({ currentApp, urlSuffix });
        window.open(URL);
      }}
    />
  );
};

export function LoginProgressPending({
  id,
  title,
}: {
  id: string;
  title: string;
}) {
  usePanda("LoginPageLoading");
  const translations = useTranslations();

  return (
    <>
      <Header>
        <HeaderWrapper>
          <Subheading light>{title}</Subheading>
          <RecareLogoOnly />
        </HeaderWrapper>
      </Header>
      <VerticalLayout margin={margin(9, 0)} height={sizing(12)}>
        <SpinnerPage id={id} />
      </VerticalLayout>
      <VerticalLayout margin={margin(0, 4)}>
        <Subheading bold>
          {translations.login.loading.settingEnvironment}
        </Subheading>
        <Body>{translations.login.loading.dontLeavePage}</Body>
      </VerticalLayout>
    </>
  );
}

export function LoginModule({
  email,
  error,
  onLogin,
  onLoginQueryProgress,
  onPasswordResetPage,
  password,
  passwordRequired,
  setEmail,
  setPassword,
  status,
}: {
  email: string;
  error: string | null;
  onLogin: () => void;
  onLoginQueryProgress: QueryProgress;
  onPasswordResetPage: () => void;
  password: string;
  passwordRequired: boolean;
  setEmail: Dispatch<SetStateAction<string>>;
  setPassword: Dispatch<SetStateAction<string>>;
  status: string;
}) {
  const translations = useTranslations();
  const emailRef = useRef();
  const passwordRef = useRef();
  const isAutofilled = useAutofilled([emailRef, passwordRef]);
  const canSubmit =
    onLoginQueryProgress !== QUERY_PROGRESS_PENDING &&
    (isAutofilled ||
      (passwordRequired && password && email) ||
      (!passwordRequired && email));

  const { app } = useEnvContext();
  const { env } = useEnvContext();

  const { redirectTitle, redirectUrl, showEnvironmentWarning, title } =
    getTitleAndUrl(app, env, translations);

  const { token } = useParams();

  useEffect(() => {
    if (token) onLogin();
  }, [token]);

  if (onLoginQueryProgress === QUERY_PROGRESS_PENDING)
    return <LoginProgressPending id="login-page" title={title} />;

  return (
    <>
      <Header>
        <HeaderWrapper>
          <Subheading bold light>
            {title}
          </Subheading>
          <RecareLogoOnly />
        </HeaderWrapper>
      </Header>

      <StyledForm
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          onLogin();
        }}
      >
        <LoginSuggestions
          setEmail={setEmail}
          setPassword={setPassword}
          onLogin={onLogin}
          currentEnv={env}
        />
        <VerticalLayout stretched style={{ marginTop: sizing(3) }}>
          {showEnvironmentWarning && (
            <div className="mb-2 w-full">
              <Banner
                message={getTranslationCompositionLink(
                  translations.login.clinic.bannerDemo,
                  "https://app.recaresolutions.com",
                )}
                noIcon
                color="warning"
              />
            </div>
          )}
          <TextInputField
            autoFocus
            onChange={setEmail}
            autoComplete="on"
            validateOverride=" "
            required
            label={translations.login.emailAddress}
            validation={onLoginQueryProgress !== QUERY_PROGRESS_FAILED}
            textInputType="email"
            elementName="email"
            value={email}
            inputRef={emailRef}
            width="90%"
          />
          {passwordRequired ? (
            <VerticalLayout>
              <HorizontalLayout stretched>
                <TextInputField
                  onChange={setPassword}
                  autoComplete="current-password"
                  label={translations.login.password}
                  validateOverride=" "
                  required
                  validation={onLoginQueryProgress !== QUERY_PROGRESS_FAILED}
                  textInputType="password"
                  elementName="password"
                  value={password}
                  inputRef={passwordRef}
                  width="90%"
                />
              </HorizontalLayout>
              <PasswordResetPageLink primary bold onClick={onPasswordResetPage}>
                {translations.login.forgotYourPassword}
              </PasswordResetPageLink>
            </VerticalLayout>
          ) : (
            <HorizontalLayout stretched>
              <Body
                display="flex"
                flex="row nowrap"
                margin={margin(2, 2, 6.25, 2)}
              >
                <LockIcon
                  style={{
                    color: ICON_DARK,
                    fontSize: FONT_SIZE_16,
                    margin: margin(0, 1, -0.5, 0),
                  }}
                  size={FONT_SIZE_16}
                />
                {translations.login.ssoEnabled}
              </Body>
            </HorizontalLayout>
          )}
        </VerticalLayout>
        {status === "login" && error != null && <Body error>{error}</Body>}
        <>
          <DialogActions
            sx={{ justifyContent: "center", margin: margin(1, 0) }}
          >
            <RSButton
              className="login"
              color="primary"
              disabled={!canSubmit}
              id="login"
              loading={onLoginQueryProgress}
              onClick={() => {}}
              style={{ width: dp(220), margin: 0 }}
              type="submit"
              variant="contained"
            >
              {translations.login.login}
            </RSButton>
          </DialogActions>
          {redirectTitle && (
            <BottomWrapper>
              <Body
                lineHeight={LINE_HEIGHT_16}
                fontSize={FONT_SIZE_12}
                secondary
              >
                {translations.login.haveAnAccountYet}&nbsp;
                <Link
                  href={translations.login.contactUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  secondary
                >
                  {translations.login.contactUs}
                </Link>
              </Body>

              <Body
                lineHeight={LINE_HEIGHT_16}
                fontSize={FONT_SIZE_12}
                secondary
              >
                {redirectTitle}&nbsp;
                <Link
                  aria-label={translations.login.clinic.clickHere}
                  href={redirectUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  secondary
                >
                  {translations.login.clinic.clickHere}
                </Link>
              </Body>
            </BottomWrapper>
          )}
        </>
      </StyledForm>
      <CountrySwitcher app={app} env={env} />
    </>
  );
}

export function ChallengeModule({
  challenge,
  error,
  onLogin,
  onLoginPage,
  onLoginQueryProgress,
  setChallenge,
  status,
}: {
  challenge: string | undefined;
  error: string | null;
  onLogin: () => void;
  onLoginPage: () => void;
  onLoginQueryProgress: QueryProgress;
  setChallenge: Dispatch<SetStateAction<string | undefined>>;
  status: string;
}) {
  const translations = useTranslations();
  const canSubmit =
    onLoginQueryProgress !== QUERY_PROGRESS_PENDING && challenge;

  const { app } = useEnvContext();
  const { env } = useEnvContext();

  const title = translations.login.challengeScreen.title;
  const datetimestring =
    window.sessionStorage.getItem(LOCAL_STORAGE_KEYS.CHALLENGE_DATETIME) ?? "";

  if (onLoginQueryProgress === QUERY_PROGRESS_PENDING)
    return <LoginProgressPending id="challenge-page" title={title} />;

  return (
    <>
      <HeaderWithIcon>
        <ChevronLeftIcon
          style={{ color: WHITE, cursor: "pointer" }}
          onClick={onLoginPage}
        />
        <HeaderWrapper>
          <Subheading bold light>
            {title}
          </Subheading>
          <RecareLogoOnly />
        </HeaderWrapper>
      </HeaderWithIcon>

      <StyledForm
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          onLogin();
        }}
      >
        <VerticalLayout stretched style={{ marginTop: sizing(3) }}>
          <VerticalLayout aligned margin={margin(4, 0)}>
            <MailIcon
              style={{ margin: margin(0, 0, 2, 0), color: ICON_GREY }}
              size={FONT_SIZE_16}
            />
            <Body>{translations.login.challengeScreen.infoText} </Body>
          </VerticalLayout>
          <TextInputField
            autoFocus
            onChange={setChallenge}
            validateOverride=" "
            required
            label={translations.login.challengeScreen.placeholder}
            validation={onLoginQueryProgress !== QUERY_PROGRESS_FAILED}
            textInputType="challenge"
            elementName="challenge"
            value={challenge ?? ""}
            width="90%"
          />
          {status === "login" && error != null && <Body error>{error}</Body>}
          {!error && (
            <Body>
              {translations.login.challengeScreen.infoTextTimestamp({
                datetimestring,
              })}
            </Body>
          )}
        </VerticalLayout>
        <DialogActions
          sx={{ justifyContent: "center", margin: margin(2, 0, 1, 0) }}
        >
          <RSButton
            className="login"
            color="primary"
            disabled={!canSubmit}
            id="login"
            loading={onLoginQueryProgress}
            onClick={() => {}}
            style={{ width: dp(220), margin: 0 }}
            type="submit"
            variant="contained"
          >
            {translations.login.login}
          </RSButton>
        </DialogActions>
      </StyledForm>
      <CountrySwitcher app={app} env={env} />
    </>
  );
}
