import {
  CAREPROVIDER_ACTIVE,
  CAREPROVIDER_CREATED,
  CAREPROVIDER_DUPLICATE,
  CAREPROVIDER_INACTIVE,
  CAREPROVIDER_INVALID,
  CAREPROVIDER_TRASH,
  COMPANY_STATUSES,
  FACILITY_SHORT_TERM_CARE,
  FACILITY_STATIC_CARE,
  MOBILE_CARE_FACILITIES,
  RADIUS_IN_KM_200,
  RADIUS_IN_KM_30,
  RADIUS_IN_KM_50,
  SEARCH_TYPE_CARE,
  SEARCH_TYPE_HOSPITAL,
  SEARCH_TYPE_REHABILITATION,
} from "core/consts";
import {
  Address,
  Careprovider,
  CareproviderAddress,
  CareproviderRoles,
  SearchType,
} from "core/types";
import { Circle } from "ds_legacy/components/Circles";
import { ERROR_COLOR, SUCCESS_COLOR } from "ds_legacy/materials/colors";

export function getFirstProviderRole(
  careproviderRoles: Array<CareproviderRoles>,
) {
  if (!careproviderRoles || careproviderRoles.length == 0) return null;

  const sortedRoles = careproviderRoles.sort(
    (a, b) => (a.careprovider?.id || -1) - (b.careprovider?.id || -1),
  );
  return sortedRoles[0];
}

export function getCareproviderStatusColors(
  status: number | null | undefined,
): string | undefined {
  switch (status) {
    case CAREPROVIDER_CREATED:
      return undefined;
    case CAREPROVIDER_INACTIVE:
    case CAREPROVIDER_DUPLICATE:
    case CAREPROVIDER_TRASH:
    case CAREPROVIDER_INVALID:
      return ERROR_COLOR;
    case CAREPROVIDER_ACTIVE:
      return SUCCESS_COLOR;
    default:
      return undefined;
  }
}

export function CompanyStatus({ status }: { status?: number }) {
  if (status == COMPANY_STATUSES.COMPANY_STATUS_CREATED) return null;
  return <Circle color={ERROR_COLOR} />;
}

export const getDefaultRadius = (
  careprovider?: Omit<Careprovider, "__typename" | "id">,
) => {
  const facilities = careprovider?.facilities || [];
  switch (careprovider?.patient_type) {
    case SEARCH_TYPE_CARE:
      if (
        facilities.some((s) =>
          [FACILITY_SHORT_TERM_CARE, FACILITY_STATIC_CARE].includes(s),
        ) &&
        facilities.every((s) => !MOBILE_CARE_FACILITIES.includes(s))
      )
        return RADIUS_IN_KM_50;
      return RADIUS_IN_KM_30;
    case SEARCH_TYPE_HOSPITAL:
      return RADIUS_IN_KM_30;
    default:
      return RADIUS_IN_KM_200;
  }
};

export const getProviderCatchmentArea = ({
  receiverPatientType,
}: {
  receiverPatientType: SearchType | null | undefined;
}) => {
  switch (receiverPatientType) {
    case SEARCH_TYPE_HOSPITAL:
      return 600;
    case SEARCH_TYPE_REHABILITATION:
      return 600;
    case SEARCH_TYPE_CARE:
      return 1000;
    default:
      return 200;
  }
};

export const hasAccessToReverseSearch = (
  careprovider: Careprovider | undefined,
): boolean => {
  if (!careprovider) return false;

  const receiverPatientType = careprovider.patient_type;

  switch (receiverPatientType) {
    case SEARCH_TYPE_CARE:
    case SEARCH_TYPE_REHABILITATION:
      return true;
    default:
      return false;
  }
};

export function isCareproviderAddress(
  address: Address | CareproviderAddress,
): address is CareproviderAddress {
  return !!(address as CareproviderAddress).zip_code;
}
