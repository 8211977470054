import {
  APP_CAREPROVIDER,
  APP_CLINIC,
  CARESEEKER_TAB_TYPE_PATIENTS,
  PATIENT_TAB_FILES,
  PATIENT_TAB_MAP,
  PATIENT_TAB_PROFILE,
  PATIENT_TAB_REHAB_FORMS,
  PATIENT_TAB_SEARCH,
  PATIENT_TAB_TIMELINE,
  PATIENT_TAB_TRANSITIONAL_CARE,
  REDIRECT_PARAM,
} from "core/consts";
import { ENV_PRODUCTION } from "core/model/config";
import { isDev } from "core/model/utils/featureFlags";
import { getBaseUrl, getQueryVariable, pathToUrl } from "core/model/utils/urls";
import {
  AssessmentSlug,
  Env,
  MessengerTabKinds,
  RehabFormName,
} from "core/types";
import { removeParamFromUrl } from "dsl/hooks/useRemoveQueryParam";
import { PatientTab } from "dsl/organisms/PatientNavigation";
import { generateParams } from "entries/receiver/Router/helpers";
import {
  Location,
  NavigateOptions,
  Path,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useTranslations } from "translations";

const dashboardRoute = "/app/clinic/dashboard";

function isPath(value: Partial<Path> | string): value is Path {
  return !!(value as Partial<Path>)?.pathname;
}

export function isExitingAssessment(pathname: string, nextPathname: string) {
  if (!pathname.includes("/assessing")) return false;
  const patientId = pathname.match(/\/patient\/(.*)\/assessing/)?.[1];

  const nextPath = isPath(nextPathname)
    ? nextPathname.pathname
    : (nextPathname as string);

  return !nextPath.includes(`/patient/${patientId}`);
}

export function checkAuctionRoute({ pathname }: { pathname: string }) {
  // check if user is in auction route
  const regex = /patient\/+(\d+)+\/auction\/+(\d+)\//;
  return regex.test(pathname);
}

export function navigateBetweenPatientAuctions({
  auctionId,
  pathname,
}: {
  auctionId: number;
  pathname: string;
}) {
  return pathname.replace(/auction\/+(\d+)\//, `auction/${auctionId}/`);
}

export function useEnhancedNavigate() {
  const location = useLocation();
  const translations = useTranslations();
  const navigate = useNavigate();

  const enhancedNavigate = (
    nextLocation: string,
    options?: NavigateOptions,
  ) => {
    const to = removeParamFromUrl("ref", nextLocation);

    if (isExitingAssessment(location.pathname, to)) {
      if (window.confirm(translations.patient.warningLeavingAssessment)) {
        return navigate(to, options);
      }
      return null;
    }
    return navigate(to, options);
  };

  return enhancedNavigate;
}

export type EnhancedNavigate = ReturnType<typeof useEnhancedNavigate>;

export const getPatientHomeUrls = ({ location }: { location: Location }) => {
  const { search } = location;
  const getPatientHomeTabUrl = ({
    auctionId,
    patientId,
    tab,
  }: {
    auctionId: number;
    patientId: number;
    tab: PatientTab;
  }) =>
    pathToUrl({
      pathname: `${dashboardRoute}/patient/${patientId}/auction/${auctionId}/${tab}`,
      search,
    });

  const urls = {
    goToPatientHome({
      auctionId,
      patientId,
      tab,
    }: {
      auctionId: number;
      patientId: number;
      tab: PatientTab;
    }) {
      return getPatientHomeTabUrl({ patientId, auctionId, tab });
    },
    goToSearch({
      auctionId,
      patientId,
    }: {
      auctionId: number;
      patientId: number;
    }) {
      return getPatientHomeTabUrl({
        auctionId,
        patientId,
        tab: PATIENT_TAB_SEARCH,
      });
    },
    goToProfile({
      auctionId,
      patientId,
    }: {
      auctionId: number;
      patientId: number;
    }) {
      return getPatientHomeTabUrl({
        auctionId,
        patientId,
        tab: PATIENT_TAB_PROFILE,
      });
    },
    goToDocumentation({
      auctionId,
      patientId,
    }: {
      auctionId: number;
      patientId: number;
    }) {
      return getPatientHomeTabUrl({
        auctionId,
        patientId,
        tab: PATIENT_TAB_TIMELINE,
      });
    },
    goToFiles({
      auctionId,
      patientId,
    }: {
      auctionId: number;
      patientId: number;
    }) {
      return getPatientHomeTabUrl({
        auctionId,
        patientId,
        tab: PATIENT_TAB_FILES,
      });
    },
    goToMap({
      auctionId,
      patientId,
    }: {
      auctionId: number;
      patientId: number;
    }) {
      return getPatientHomeTabUrl({
        auctionId,
        patientId,
        tab: PATIENT_TAB_MAP,
      });
    },
    goToTransitionalCare({
      auctionId,
      patientId,
    }: {
      auctionId: number;
      patientId: number;
    }) {
      return getPatientHomeTabUrl({
        auctionId,
        patientId,
        tab: PATIENT_TAB_TRANSITIONAL_CARE,
      });
    },
    goToRehabForms({
      auctionId,
      patientId,
      type,
    }: {
      auctionId: number;
      patientId: number;
      type?: RehabFormName;
    }) {
      const pathname = `${dashboardRoute}/patient/${patientId}/auction/${auctionId}/${PATIENT_TAB_REHAB_FORMS}`;

      return pathToUrl({
        pathname: type ? `${pathname}/${type}` : pathname,
        search,
      });
    },
  };

  return urls;
};

const patientHomeNavigationHandlers = ({
  location,
  navigate,
}: {
  location: Location;
  navigate: EnhancedNavigate;
}) => {
  const urls = getCareseekerHandlerUrls({ location });

  return {
    goToSearch({
      auctionId,
      patientId,
    }: {
      auctionId: number;
      patientId: number;
    }) {
      navigate(urls.goToSearch({ patientId, auctionId }));
    },
    goToProfile({
      auctionId,
      patientId,
    }: {
      auctionId: number;
      patientId: number;
    }) {
      navigate(urls.goToProfile({ patientId, auctionId }));
    },
    goToDocumentation(patientId: number, auctionId: number) {
      navigate(urls.goToDocumentation({ patientId, auctionId }));
    },
    goToFiles(patientId: number, auctionId: number) {
      navigate(urls.goToFiles({ patientId, auctionId }));
    },
    goToMap(patientId: number, auctionId: number) {
      navigate(urls.goToMap({ patientId, auctionId }));
    },
    goToTransitionalCare(patientId: number, auctionId: number) {
      navigate(urls.goToTransitionalCare({ patientId, auctionId }));
    },
    goToRehabForms(patientId: number, auctionId: number, type?: RehabFormName) {
      navigate(urls.goToRehabForms({ patientId, auctionId, type }));
    },
    goToPatientHomeTab({
      auctionId,
      patientId,
      tab,
    }: {
      auctionId: number;
      patientId: number;
      tab: PatientTab;
    }) {
      navigate(urls.goToPatientHome({ patientId, auctionId, tab }));
    },
  };
};

export const getCareseekerHandlerUrls = ({
  location,
}: {
  location: Location;
}) => {
  const { search } = location;

  const urls = {
    goToLogin() {
      return pathToUrl({ pathname: `/auth`, search });
    },
    goToProductionEnv() {
      return getBaseUrl({ env: ENV_PRODUCTION, isDev, app: APP_CLINIC });
    },
    goToContactUs() {
      return "https://recaresolutions.com/kontakt/";
    },
    goToProviderApp() {
      const env = getQueryVariable(search, "env") as Env;
      return getBaseUrl({ env, isDev, app: APP_CAREPROVIDER });
    },
    goToForgotPassword() {
      return pathToUrl({
        pathname: `/forgot-password`,
        search,
      });
    },
    goToDashboard() {
      return pathToUrl({
        pathname: dashboardRoute,
        search,
      });
    },
    changePassword() {
      return pathToUrl({
        pathname: `${dashboardRoute}/my_password_change`,
        search: `${REDIRECT_PARAM}=${encodeURIComponent(
          `${location.pathname}${location.search}`,
        )}`,
      });
    },
    goToTodo(tabName?: string) {
      return pathToUrl({
        pathname: `${dashboardRoute}/todo/${
          tabName ? `${tabName}` : CARESEEKER_TAB_TYPE_PATIENTS
        }`,
        search: "",
      });
    },
    goToReport() {
      return pathToUrl({
        pathname: `${dashboardRoute}/report`,
        search,
      });
    },
    goToUsers() {
      return pathToUrl({
        pathname: `${dashboardRoute}/users`,
        search: "",
      });
    },
    goToPredict() {
      return pathToUrl({
        pathname: `${dashboardRoute}/predict`,
        search,
      });
    },
    goToPredictPatientDetails(patientId: string, dischargeProbability: number) {
      return pathToUrl({
        pathname: `${dashboardRoute}/predict/patient/${patientId}?dischargeProbability=${dischargeProbability}`,
        search,
      });
    },
    goToOnPremise({ caseId, page }: { caseId?: string; page: string }) {
      return pathToUrl({
        pathname: `${dashboardRoute}/onpremsetup/${page}${
          caseId ? `?external_id=${caseId}` : ""
        }`,
        search: "",
      });
    },
    patient: {
      new(props?: { external_id?: string; origin?: string }) {
        return pathToUrl({
          pathname: `${dashboardRoute}/patient/new`,
          search: `${
            props?.external_id
              ? `external_id=${props.external_id}${
                  props?.origin ? `&origin=${props.origin}` : ""
                }`
              : ""
          }`,
        });
      },
      goToAssessing({
        auctionId,
        patientId,
      }: {
        auctionId: number;
        patientId: number;
      }) {
        return pathToUrl({
          pathname: `${dashboardRoute}/patient/${patientId}/auction/${auctionId}/assessing/transfer`,
          search,
        });
      },
      goToassessmentSlug({
        assessmentSlug,
        auctionId,
        patientId,
        sessionId,
      }: {
        assessmentSlug?: AssessmentSlug;
        auctionId: number;
        patientId: number;
        sessionId?: string;
      }) {
        return pathToUrl({
          pathname: `${dashboardRoute}/patient/${patientId}/auction/${auctionId}/assessing/${
            assessmentSlug ?? ""
          }`,
          search: `${sessionId ? `session_id=${sessionId}` : ""}`,
        });
      },
      goToBarthel({
        auctionId,
        patientId,
      }: {
        auctionId: number;
        patientId: number;
      }) {
        return pathToUrl({
          pathname: `${dashboardRoute}/patient/${patientId}/auction/${auctionId}/barthel-index`,
          search,
        });
      },
      goToEarlyRehab({
        auctionId,
        patientId,
      }: {
        auctionId: number;
        patientId: number;
      }) {
        return pathToUrl({
          pathname: `${dashboardRoute}/patient/${patientId}/auction/${auctionId}/early-rehab-index`,
          search,
        });
      },
      goToSinger({
        auctionId,
        patientId,
      }: {
        auctionId: number;
        patientId: number;
      }) {
        return pathToUrl({
          pathname: `${dashboardRoute}/patient/${patientId}/auction/${auctionId}/singer-index`,
          search,
        });
      },
      goToHome({
        auctionId,
        patientId,
      }: {
        auctionId?: number;
        patientId: number;
      }) {
        const auctionParam = auctionId ? `/auction/${auctionId}` : "";
        return pathToUrl({
          pathname: `${dashboardRoute}/patient/${patientId}${auctionParam}`,
          search,
        });
      },
      goToRequest({
        auctionId,
        params,
        patientId,
        requestId,
      }: {
        auctionId: number;
        params?: {
          activeTab?: MessengerTabKinds;
          recommendation?: boolean;
        };
        patientId: number;
        requestId: number;
      }) {
        if (requestId <= 0) {
          console.error(
            `[DEV-14068] [goToRequest] tried to access bad request id ${requestId}`,
          );
          return "";
        }

        const recommendationParam = params?.recommendation ? "true" : undefined;

        const searchParams =
          generateParams([
            ["activeTab", params?.activeTab],
            ["recommendation", recommendationParam],
          ]) ?? "";

        return pathToUrl({
          pathname: `${dashboardRoute}/patient/${patientId}/auction/${auctionId}/request/${requestId}`,
          search: searchParams,
        });
      },
    },
    goToAuction({
      auctionId,
      patientId,
    }: {
      auctionId: number;
      patientId?: number;
    }) {
      const isAuctionRoute = checkAuctionRoute({
        pathname: location.pathname,
      });
      if (isAuctionRoute) {
        const nextPathname = navigateBetweenPatientAuctions({
          pathname: location.pathname,
          auctionId,
        });
        return pathToUrl({ pathname: nextPathname, search });
      } else {
        return pathToUrl({
          pathname: `${dashboardRoute}/patient/${patientId}/auction/${auctionId}`,
          search,
        });
      }
    },
    replaceByHome({
      auctionId,
      patientId,
    }: {
      auctionId: number;
      patientId?: number;
    }) {
      return pathToUrl({
        pathname: `${dashboardRoute}/patient/${patientId}/auction/${auctionId}`,
        search,
      });
    },
    replaceByProfile({
      auctionId,
      patientId,
    }: {
      auctionId: number;
      patientId?: number;
    }) {
      return pathToUrl({
        pathname: `${dashboardRoute}/patient/${patientId}/auction/${auctionId}/profile`,
        search,
      });
    },
    replaceByassessmentSlug(
      assessmentSlug: AssessmentSlug,
      patientId: number,
      auctionId: number,
    ) {
      return pathToUrl({
        pathname: `${dashboardRoute}/patient/${patientId}/auction/${auctionId}/assessing/${assessmentSlug}`,
        search,
      });
    },
    ...getPatientHomeUrls({
      location,
    }),
    replaceByAuth() {
      return pathToUrl({
        pathname: `/auth`,
        search,
      });
    },
  };

  return urls;
};

export function getCareseekerNavigationHandlers({
  location,
  navigate,
}: {
  location: Location;
  navigate: EnhancedNavigate;
}) {
  const urls = getCareseekerHandlerUrls({ location });

  return {
    goToLogin() {
      navigate(urls.goToLogin());
    },
    goToForgotPassword() {
      navigate(urls.goToForgotPassword());
    },
    changePassword() {
      navigate(urls.changePassword());
    },
    goToTodo(tabName?: string) {
      navigate(urls.goToTodo(tabName));
    },
    goToReport() {
      navigate(urls.goToReport());
    },
    goToUsers() {
      navigate(urls.goToUsers());
    },
    goToPredict() {
      navigate(urls.goToPredict());
    },
    goToPredictPatientDetails(patientId: string, dischargeProbability: number) {
      navigate(urls.goToPredictPatientDetails(patientId, dischargeProbability));
    },
    goToOnPremise({ caseId, page }: { caseId?: string; page: string }) {
      navigate(urls.goToOnPremise({ page, caseId }));
    },
    patient: {
      new(props?: { external_id?: string; origin?: string }) {
        navigate(urls.patient.new(props));
      },
      goToAssessing({
        auctionId,
        patientId,
      }: {
        auctionId: number;
        patientId: number;
      }) {
        navigate(urls.patient.goToAssessing({ patientId, auctionId }));
      },
      goToassessmentSlug({
        assessmentSlug,
        auctionId,
        patientId,
        sessionId,
      }: {
        assessmentSlug?: AssessmentSlug;
        auctionId: number;
        patientId: number;
        sessionId?: string;
      }) {
        navigate(
          urls.patient.goToassessmentSlug({
            patientId,
            auctionId,
            assessmentSlug,
            sessionId,
          }),
        );
      },
      goToBarthel({
        auctionId,
        patientId,
      }: {
        auctionId: number;
        patientId: number;
      }) {
        navigate(urls.patient.goToBarthel({ auctionId, patientId }));
      },
      goToEarlyRehab({
        auctionId,
        patientId,
      }: {
        auctionId: number;
        patientId: number;
      }) {
        navigate(urls.patient.goToEarlyRehab({ auctionId, patientId }));
      },
      goToSinger({
        auctionId,
        patientId,
      }: {
        auctionId: number;
        patientId: number;
      }) {
        navigate(urls.patient.goToSinger({ auctionId, patientId }));
      },
      goToHome({
        auctionId,
        patientId,
      }: {
        auctionId?: number;
        patientId: number;
      }) {
        navigate(urls.patient.goToHome({ patientId, auctionId }), {
          state: {
            prevLocation: encodeURIComponent(
              `${location.pathname}${location.search}`,
            ),
          },
        });
      },
      goToRequest({
        auctionId,
        params,
        patientId,
        requestId,
      }: {
        auctionId: number;
        params?: {
          activeTab?: MessengerTabKinds;
          recommendation?: boolean;
        };
        patientId: number;
        requestId: number;
      }) {
        if (requestId <= 0) {
          console.error(
            `[DEV-14068] [goToRequest] tried to access bad request id ${requestId}`,
          );
          return;
        }

        navigate(
          urls.patient.goToRequest({ requestId, patientId, auctionId, params }),
        );
      },
      goToAuction({
        auctionId,
        patientId,
      }: {
        auctionId: number;
        patientId?: number;
      }) {
        navigate(urls.goToAuction({ auctionId, patientId }), { replace: true });
      },
      replaceByHome({
        auctionId,
        patientId,
      }: {
        auctionId: number;
        patientId?: number;
      }) {
        navigate(urls.replaceByHome({ patientId, auctionId }), {
          replace: true,
        });
      },
      replaceByProfile(patientId: number, auctionId: number) {
        navigate(urls.replaceByProfile({ patientId, auctionId }), {
          replace: true,
        });
      },
      replaceByassessmentSlug({
        assessmentSlug,
        auctionId,
        patientId,
      }: {
        assessmentSlug: AssessmentSlug;
        auctionId: number;
        patientId: number;
      }) {
        navigate(
          urls.replaceByassessmentSlug(assessmentSlug, patientId, auctionId),
          { replace: true },
        );
      },
      ...patientHomeNavigationHandlers({
        navigate,
        location,
      }),
    },
    replaceByAuth() {
      navigate(urls.replaceByAuth(), { replace: true });
    },
  };
}
